import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="November 2022"
      subnav="release-notes">
      <div id="November2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Check back throughout the month for more updates!
        </Paragraph>
        <div className={styles.releaseInfo}>
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Added>
            </div>
          </div> */}
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.25.0"
              versionUrl="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform"
            />
            <div className={styles.changesList}>
              <Added>
                The <code>showCount</code> prop the the{' '}
                <Link href="/components/modules/bar-list/code?web#showCount">
                  Bar List
                </Link>{' '}
                to display raw counts rather than percentages.
              </Added>
              <Fixed>
                Incorrect color value for default content color in dark
                environments.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.3.0"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Improved>
                Jarvis version to 6.0.0 and other quarterly maintenance items.
              </Improved>
            </div>
          </div>
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Fixed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Storybook" />
            <div className={styles.changesList}>
              <Removed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Removed>
            </div>
          </div> */}
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
